import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Info() {
  return (
    <>
      <section className="section-info" style={{background:"#f4f5f7"}}>
        <Container>
          <Row>
            <Col lg="4" md="6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                  <i className="ni ni-user-run"></i>
                </div>
                <h6 className="info-title text-uppercase text-primary pl-0">
                  Emotion extraction
                </h6>
                <p className="description opacity-8">
                Knowing people's opinions about your products and your ideas in an easy and simple way, and producing results in the form of organized statistics 
                </p>
               
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="info text-left" style={{ marginTop: "-50px" }}>
                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                  <i className="ni ni-atom"></i>
                </div>
                <h6 className="info-title text-uppercase text-success pl-0">
                  Multiple statistics Forms
                </h6>
                
                <p className="description opacit
                y-8">
                Output statistics in various forms from different social media platforms, with many filters such as geographical location and ...
                </p>
                
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                  <i className="ni ni-istanbul"></i>
                </div>
                <h6 className="info-title text-uppercase text-warning pl-0">
                Social Media
                </h6>
                <p className="description opacity-8">
                If you want to collect opinions from different social networking sites and want to classify these opinions without searching thousands of pages, you can easily extract these opinions.
                </p>
             
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Info;

import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Features() {
  return (
    <>
      <section className="section-features bg-secondary"  style={{background: "white"}}>
        <Container fluid style={{background: "white"}}>
          <Row style={{background: "white"}}> 
            <Col className="pt-5" lg="6" md="12">
              <div className="info info-horizontal">
                <div className="icon icon-shape icon-shape-warning rounded-circle text-white">
                  <i className="ni ni-money-coins text-warning"></i>
                </div>
                <div className="description pl-4 pt-2">
                  <h5 className="title">Save Money</h5>
                  <p>
                  It is known to everyone that gathering world opinions and feelings about products and services needs great effort and time and needs many human cadres and huge money, and by this we will reduce the cost and the great human effort expended.
                  </p>
                </div>
              </div>
              </Col>
              <Col  className="pt-5" lg="6" md="12">
              <div className="info info-horizontal">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-bold text-info"></i>
                </div>
                <div className="description pl-4 pt-2">
                  <h5 className="title">Effective method</h5>
                  <p>
                  
It will be indispensable for the traditional methods used, such as questionnaires and questions, in ways that are useless, so that we can use the communication sites in which we talk without asking.
                  </p>
                </div>
              </div>
              </Col>
              </Row>
              <Row>
                <Col  className="pt-5" >
                <div className="info info-horizontal">
                  <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                    <i className="ni ni-paper-diploma text-danger"></i>
                  </div>
                  <div className="description pl-4 pt-2">
                    <h5 className="title">Save Time</h5>
                    <p>
                    Reviews can be obtained, analyzed and viewed within a short time, unlike the human effort, which requires a large time to search thousands of pages on social media.
                    </p>
                  </div>
                </div>
            </Col>
           
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Features;

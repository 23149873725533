import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import HeaderPresentation from "components/headers/HeaderPresentation.js";
// Sections for this page
import Info from "components/presentation-page/Info.js";
import BasicComponents from "components/presentation-page/BasicComponents.js";

import Sections from "components/presentation-page/Sections.js";
import Features from "components/presentation-page/Features.js";

function Presentation() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    return function cleanup() {
      document.body.classList.remove("presentation-page");
    };
  });
  return (
    <>
      <DemoNavbar type="primary" />
      <div className="wrapper">
        <HeaderPresentation />
        <Info />
        <BasicComponents />
         
        <Sections />  
        <Features />
        <DemoFooter />
      </div>
    </>
  );
}

export default Presentation;

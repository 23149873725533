import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function HeaderPresentation() {
  return (
    <>
      <div className="section section-hero section-shaped">
        <div className="page-header">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/ill/presentation_bg.png") + ")",
            }}
          ></div>
          <Container
            className="shape-container d-flex align-items-center py-lg"
            fluid
          >
            <div className="col px-0">
              <Row>
                <Col className="ml-5" lg="4">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/brand/logo.png")}
                    style={{ width: "400px" }}
                  ></img>{" "}
                  <h1 className="display-3">Establish strong relationship with your customers</h1>
                  <p className="lead">
                   <b> Discover your customer's emotions, engage with your community, and let your audience navigate your success.
                    </b>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default HeaderPresentation;

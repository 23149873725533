import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function BasicComponents() {
  return (
    <>
      <section className="section-basic-components">
        <Container>
          <Row>
            <Col className="mb-md-5" lg="5" md="10">
              <h1 className="display-3">
                
                <span className="text-primary">
                Social networks and Emotions
                </span>
              </h1>
              <p className="lead">
              We collect different opinions from thousands of pages on social networking sites and organize them in a professional way in order to extract statistics presented in an organized and accurate manner so that you remain aware of everything new about opinions about your services or products.
              </p>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="table-img"
                  src={require("assets/img/presentation-page/table.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={require("assets/img/presentation-page/card-btn.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={require("assets/img/presentation-page/card-orange.png")}
                ></img>
              
               
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BasicComponents;

import React from "react";
import { Container, Row, Col } from "reactstrap";

// Core Components

function Sections() {
  return (
    <>
      <section className="section-sections bg-secondary">
        <Container>
          <Col className="mx-auto" md="8">
            <div className="section-description text-center">
              <h2 className="display-2">Statistics</h2>
              <p className="lead">
              We will display stats in a variety of ways and in different forms according to several filters, the most important of which is geographical location and date.
              </p>
              
            </div>
          </Col>
        </Container>
        <Container fluid>
          <div className="section-cols">
            <Row className="mt-5 mb-5">
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/header-1.jpg")}
                ></img>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/header-2.jpg")}
                ></img>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/feature-1.jpg")}
                ></img>
              </Col>
              
            </Row>
            <Row>
              
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/feature-3.jpg")}
                ></img>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/feature-4.jpg")}
                ></img>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="shadow"
                  src={require("assets/img/presentation-page/sections/feature-5.jpg")}
                ></img>
              </Col>
            </Row>
            
        
          </div>
        </Container>
      </section>
    </>
  );
}

export default Sections;
